import splide, { customSplide } from "../utils/splide";

const banner = document.getElementById("banner");
if (banner) {
  const bannerSplide = customSplide(banner, {
    type: "fade",
    rewind: true,
    perPage: 1,
    autoplay: true,
    speed: 2000,
    lazyLoad: "sequential",
    pagination: true,
    arrows: banner.dataset.slides > 1 ? true : false,
    breakpoints: {
      1200: {
        pagination: true,
        arrows: false,
      },
      600: {
        fixedHeight: "unset",
      }
    },
  });
  bannerSplide.mount();
}

const consortium = document.getElementById("consortium-splide");
if (consortium) {
  const consortiumSplide = splide(consortium, {
    perPage: 6,
    breakpoints: {
      1200: {
        perPage: 4,
      },
      600: {
        perPage: 2,
        arrows: true,
        pagination: true,
      }
    }
  });
  consortiumSplide.mount()
}

// const number = document.querySelectorAll('.numbers-article__header h1')
// window.addEventListener('scroll', () => {
//   const elementPosition = number[0].getBoundingClientRect();
//   const screenPosition = window.innerHeight / 2;

//   if (elementPosition.top < screenPosition && elementPosition.bottom > screenPosition) {
//     number.forEach(el => {
//       var currentValue = parseInt(el.innerHTML)
//       var newValue = 0
//       var intervalId

//       function updateValue() {
//         newValue++
//         el.innerHTML = `+${newValue}mil`
//         if (newValue === currentValue) {
//           clearInterval(intervalId)
//         }
//       }

//       if (newValue !== currentValue) intervalId = setInterval(updateValue, 1)
//     })
//   }
// })

const proceeding = document.getElementById('proceeding')
if(proceeding) {
  const proceedingSlide = customSplide(proceeding, {
    type: "loop",
    lazyLoad: "sequential",
    gap: "3em",
    perPage: 3,
    autoplay: false,
    speed: 2000,
    arrows: proceeding.dataset.slides > 3 ? true : false,
    pagination: false,
    breakpoints: {
      990: {
        perPage: 2,
      },
      600: {
        perPage: 1,
        arrows: true,
      },
    }
  })
  proceedingSlide.mount();
}

const advantage = document.getElementById("advantage")
if (advantage) {
  const advantageSplide = customSplide(advantage, {
    type: "loop",
    gap: "1em",
    perPage: 3,
    autoplay: false,
    speed: 2000,
    arrows: advantage.dataset.slides > 3 ? true : false,
    pagination: false,
    breakpoints: {
      1200: {
        perPage: 2,
        arrows: false,
        pagination: true,
      },
      600: {
        perPage: 1,
      },
    }
  })
  advantageSplide.mount();
}

const news = document.getElementById("news");
if (news) {
  const newsSplide = splide(news, {
    perPage: 3,
    breakpoints: {
      1200: {
        perPage: 2,
        arrows: false,
        pagination: true,
      },
      600: {
        perPage: 1,
        pagination: true,
      },
    },
  });
  newsSplide.mount();
}

const faqArrow = document.querySelectorAll('.faq__card-question')
faqArrow.forEach(arrow => {
  arrow.addEventListener('click', () => {
    const upDownArrow = arrow.querySelector('i').classList.contains('fa-chevron-down') ? 'fa-chevron-up' : 'fa-chevron-down'
    arrow.querySelector('i').classList.toggle(upDownArrow)
  })
});

window.openSocials = function (id) {
  const social = document.getElementById(id)
  social.classList.toggle('social-content--open');
}
