import Splide from "@splidejs/splide";

export default function splide(
    element,
    {
        perPage = 4,
        pagination = false,
        arrows = element.dataset.slides > perPage ? true : false,
        autoplay = true,
        speed = 2000,
        gap = '1em',
        breakpoints = {
            1200: {
                perPage: 4,
                pagination: true,
                arrows: false,
            },
            600: {
                perPage: 2,
            },
        },
    } = {}
) {
    return new Splide(element, {
        type: "slide",
        rewind: true,
        gap: gap,
        perPage: perPage,
        autoplay: autoplay,
        speed: speed,
        lazyLoad: "sequential",
        pagination: pagination,
        arrows: arrows,
        breakpoints: breakpoints,
    });
}

export function customSplide(element, config) {
    return new Splide(element, config);
}
